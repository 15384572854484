export const baseColor = '#993301'
export const baseGray = '#AAAAAA'
export const baseShadow = '0 0 8px rgba(0, 0, 0, 0.3)'
export const smallWindow = 768

// Pages
export const routes = {
    home: {
        title: 'home',
        path: '/',
    },
    chiSono: {
        title: 'chi sono',
        path: '/chisono',
    },
    cosaFaccio: {
        title: 'cosa faccio',
        path: '/cosafaccio',
    },
}

// Motions
export const motionSlide = (delay: number) => ({
    initial: { x: '-5%', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    transition: { duration: 1, ease: "easeInOut", delay: delay },
});
export const motionSlideUp = (delay: number) => ({
    initial: { y: '+5%', opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 1, ease: "easeInOut", delay: delay },
});
export const motionFadeIn = (delay: number) => ({
    initial: { x: 0, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    transition: { duration: 1.2, ease: "easeInOut", delay: delay },
});