import { useState, useNavigate, Container, Navbar, Nav, LiaTimesSolid, LiaBarsSolid } from '../Utils/Imports'
import { baseColor, baseGray, baseShadow, routes } from '../Utils/Constants'
import { contactInfo, iconInfo } from '../Info'
import { useLocation } from 'react-router-dom'

const smallTextSize = '0.8rem'

export function TopBar() {
  const navigate = useNavigate()
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(true)

  const getLinkStyle = (path: string) => {
    return location.pathname === path ? { color: baseColor } : {};
  };

  const handleNavClick = (path: string) => {
    navigate(path)
    window.scrollTo(0, 0)
    setCollapsed(true)
  }

  return (
    <div style={{ marginBottom: '4rem' }}>
      <Navbar
        style={{ boxShadow: baseShadow, borderBottom: `3px solid ${baseColor}`, background: 'white' }}
        fixed="top"
        expand="md"
        expanded={!collapsed}
        onToggle={() => setCollapsed(!collapsed)}
      >
        <Container>
          <Navbar.Brand onClick={() => handleNavClick('/')} style={{ fontFamily: 'Arial Rounded MT Bold' }}>
            <span style={{ color: baseGray }}>damianotedeschi</span>architetto
          </Navbar.Brand>
          <Navbar.Toggle style={{ border: 'none' }}>
            {collapsed ? <LiaBarsSolid size={24} /> : <LiaTimesSolid size={24} />}
          </Navbar.Toggle>
          <Navbar.Collapse>
            <Nav className="ms-auto">
              <Nav.Link onClick={() => handleNavClick(routes.home.path)} style={getLinkStyle(routes.home.path)}>home</Nav.Link>
              <Nav.Link onClick={() => handleNavClick(routes.chiSono.path)} style={getLinkStyle(routes.chiSono.path)}>chi sono</Nav.Link>
              <Nav.Link onClick={() => handleNavClick(routes.cosaFaccio.path)} style={getLinkStyle(routes.cosaFaccio.path)}>cosa faccio</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

const linkStyles = { textDecoration: 'none', color: 'inherit' }

function Contacts() {
  return (
    <div>
      {contactInfo.map(({ href, label }, index) => (
        <a key={index} href={href} style={linkStyles}>
          {label}
          <br />
        </a>
      ))}
    </div>
  )
}

function Icons() {
  return (
    <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', color: baseGray }}>
      {iconInfo.map(({ href, icon }, index) => (
        <a key={index} href={href} style={linkStyles}>
          {icon}
        </a>
      ))}
    </div>
  )
}

export function BottomBar() {
  return (
    <div>
      <Navbar style={{ boxShadow: baseShadow }} sticky='bottom'>
        <Container>
          <div style={{ margin: '0.5rem', display: 'flex', alignItems: 'center', gap: '2rem' }}>
            <div style={{ textAlign: 'center' }}>
              <svg style={{ width: '6rem', height: '6rem', borderRadius: '50%' }}>
                <image
                  href="/Damiano1.jpeg"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="xMidYMid slice"
                />
              </svg>
              <div style={{ color: baseColor }}>damiano tedeschi</div>
              <div style={{ fontSize: smallTextSize, color: baseGray }}>architetto</div>
            </div>
            <div style={{ fontSize: smallTextSize }}>
              <Contacts />
              <Icons />
            </div>
          </div>
        </Container>
      </Navbar>
    </div >
  )
}