import { CustomInfo } from '../Utils/Functions'
import { chiSonoTextSmall, cosaFaccioList } from '../Info'

export function Home() {
  return (
    <div>
      <CustomInfo title='chi sono' path='/ChiSono' text={chiSonoTextSmall} image='/Damiano2' />
      <CustomInfo title='cosa faccio' path='/Cosafaccio' text={cosaFaccioList} image='/Image1' />
    </div >
  )
}