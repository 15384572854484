import { FaPhoneAlt, FaLinkedin, FaFacebook, FaInstagram, IoMdMail } from './Utils/Imports'

export const contactInfo = [
    { href: "https://www.google.com/maps/place/Via+Alba+Maldini,+8,+40069+Zola+Predosa+BO/@44.4850499,11.2114991,17z/data=!3m1!4b1!4m6!3m5!1s0x477fd700a89ca9b5:0xfff9dceb493a52ef!8m2!3d44.48505!4d11.21637!16s%2Fg%2F11cs7skrbc?entry=ttu&g_ep=EgoyMDI0MTEwNS4wIKXMDSoASAFQAw%3D%3D", label: "Via Alba Maldini 8 Zola Predosa BO" },
    { href: "tel:+393336536062", label: "+39 333 6536062" },
    { href: "mailto:damianotedeschi@yahoo.it", label: "damianotedeschi@yahoo.it" },
    { href: "mailto:damiano.tedeschi@archiworldpec.it", label: "PEC damiano.tedeschi@archiworldpec.it" },
    { label: "P.IVA 02854311202" },
    { label: "CF TDSDMN68R01A944Y" },
]

export const iconInfo = [
    { href: "tel:+393336536062", icon: <FaPhoneAlt size={16} /> },
    { href: "mailto:damianotedeschi@yahoo.it", icon: <IoMdMail size={23} /> },
    { href: "https://it.linkedin.com/in/damiano-tedeschi-6452a1163", icon: <FaLinkedin size={20} /> },
    { href: "https://www.facebook.com/damiano.tedeschi.5855/?_rdr", icon: <FaFacebook size={20} /> },
    { href: "https://www.instagram.com/damiano_tedeschi", icon: <FaInstagram size={20} /> },
]

export const chiSonoTextSmall = [
    `Sono Damiano Tedeschi, architetto con oltre 25 anni di esperienza in progettazione, ristrutturazione e coordinamento di lavori edili.
    Specializzato in sicurezza, prevenzione incendi e consulenze per il Tribunale, ho lavorato su progetti residenziali,
    commerciali e scolastici, creando soluzioni innovative e integrate.`,
]

export const chiSonoText = [
    `<strong>Studi e Formazione</strong>
    Sono Damiano Tedeschi, nato a Bologna il 1° ottobre 1968. Mi sono laureato in Architettura presso l’Università degli Studi di Firenze. 
    Dopo aver conseguito l’abilitazione professionale, mi sono iscritto all’Albo degli Architetti della Provincia di Bologna nel gennaio 1994.
    Nel 1999 ho ottenuto l’abilitazione come Coordinatore per la sicurezza in fase di progettazione e di esecuzione (D.lgs. 494/96), 
    e la specializzazione in prevenzione incendi (Legge 818/84).`,

    `<strong>Carriera Professionale</strong>
    La mia carriera si è sviluppata in studi di Architettura e Ingegneria, dove ho acquisito esperienza nella redazione di progetti esecutivi
    per diverse tipologie di edifici: residenziali, commerciali, scolastici, produttivi e terziari, oltre a gestire la direzione dei lavori. 
    Mi sono specializzato nella ristrutturazione edilizia, nel restauro e nella progettazione di interni,
    e mi occupo anche di progettazione per allestimenti temporanei, come quelli fieristici e sportivi motoristici.
    Lavorando a stretto contatto con team di ingegneri e professionisti, ho sviluppato competenze avanzate nella progettazione integrata e coordinata. 
    Mi occupo inoltre di valutazioni economiche per investimenti immobiliari e di analisi di marginalità, oltre a dirigere lavori edili e allestimenti temporanei. 
    Infine, offro consulenze tecniche per il Tribunale, fornendo perizie e analisi specialistiche, mettendo la mia esperienza al servizio della giustizia.`
]

export const cosaFaccioList = [
    `<strong>Progettazione</strong> - Cura delle esigenze del cliente in tutte le sue sfaccettature.`,
    `<strong>Organizzazione</strong> - Gestione di tutti gli aspetti tecnici e realizzativi.`,
    `<strong>Realizzazione</strong> - Concretizzazione delle aspettative del cliente.`,
    `<strong>Direzione Lavori</strong> - Supervisione delle esigenze del cliente in tutte le sue fasi.`,
    `<strong>Studi di Fattibilità</strong> - Esperienza a servizio del cliente.`,
    `<strong>Analisi Commerciali ed Economiche</strong> - Professionalità a servizio del cliente.`,
    `<strong>Perizie e Consulenze</strong> - Esperienza e professionalità a disposizione del cliente.`,
]

export const cosaFaccioText = [
    `Ho le competenze per occuparmi di tutto l’iter progettuale e realizzativo della commessa:
    dallo studio di fattibilità alla progettazione architettonica, dalla progettazione esecutiva alla direzione lavori,
    fino alla rappresentazione grafica in 3D dell’intervento e alla sua visualizzazione per fini promozionali.`,

    `Attraverso qualificate partnership, offro la progettazione e la realizzazione di tutta l’impiantistica,
    garantendo la consegna "chiavi in mano" di qualsiasi richiesta, sia in ambito edile (civile, industriale, ristrutturazioni, recuperi, ecc.),
    sia nell’allestimento e gestione di eventi temporanei per spettacoli, manifestazioni sportive o fieristiche.`,

    `In questo ambito, l’esperienza che ho maturato nel tempo mi permette di valutare le criticità nella gestione di uno spazio,
    individuando soluzioni efficaci per ottimizzare la gestione da parte dello staff organizzativo, mantenendo al contempo uno
    spazio piacevole e funzionale per i visitatori.`,
]