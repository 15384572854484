import { useNavigate } from './Imports'
import { baseColor, baseShadow, motionSlide, motionSlideUp, motionFadeIn, smallWindow } from './Constants'
import { motion } from 'framer-motion'

export function CustomInfo({ title, path = "", text, image, isBig }: { title: string, path?: string, text: string[], image: string, isBig?: boolean }) {
    const navigate = useNavigate()
    function handleButtonClick(path: string) {
        navigate(path)
        window.scrollTo(0, 0)
    }
    const isSmallWindow = window.innerWidth < smallWindow
    const isColumn = isSmallWindow || isBig
    const isBigImage = isBig && !isSmallWindow
    const row = title === "" ? 'row-reverse' : 'row'
    return (
        <div style={{ margin: '1.5rem' }} >
            <motion.div {...motionSlide(0)}>
                <h2 style={{ color: baseColor, paddingLeft: '20%', textAlign: 'left', paddingBottom: '1rem' }}>{title}</h2>
            </motion.div>
            <div style={{ display: 'flex', flexDirection: isColumn ? 'column' : row, gap: '2rem', alignItems: 'center' }}>
                <motion.div {...motionFadeIn(0)}>
                    <svg style={{ flexShrink: 0, width: isBigImage ? '45rem' : '15rem', height: isBigImage ? '30rem' : '15rem', borderRadius: '3%', boxShadow: baseShadow }}>
                        <image
                            href={image + '.jpeg'}
                            width="100%"
                            height="100%"
                            preserveAspectRatio="xMidYMid slice"
                        />
                    </svg>
                </motion.div>
                <div>
                    <motion.div {...motionSlideUp(0.5)}>
                        {text.map((text, index) => (
                            <div key={index} dangerouslySetInnerHTML={{ __html: text }} style={{ marginTop: '0.2rem', textAlign: 'justify' }} />
                        ))}
                    </motion.div>
                    <motion.div {...motionFadeIn(1)}>
                        <button hidden={path === ""} onClick={() => handleButtonClick(path)} style={{ margin: '1rem', background: 'none', border: 'none', color: 'black' }}>
                            Scopri di più <span style={{ color: baseColor }}>→</span>
                        </button>
                    </motion.div>
                </div>
            </div>
        </div >
    )
}