import { CustomInfo } from '../Utils/Functions'
import { cosaFaccioList, cosaFaccioText } from '../Info'

export function CosaFaccio() {
  return (
    <div>
      <CustomInfo title='cosa faccio' text={cosaFaccioList} image='/Image1' />
      <CustomInfo title='' text={cosaFaccioText} image='/Image2' />
    </div >
  )
}