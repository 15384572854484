import { HashRouter, Route, Routes, } from './Utils/Imports'
import { routes } from './Utils/Constants'
import { TopBar, BottomBar } from './Components/Bar'
import { Home } from './Pages/Home'
import { ChiSono } from './Pages/ChiSono'
import { CosaFaccio } from './Pages/CosaFaccio'

function App() {
  return (
    <HashRouter>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TopBar />
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path={routes.home.path} element={<Home />} />
            <Route path={routes.chiSono.path} element={<ChiSono />} />
            <Route path={routes.cosaFaccio.path} element={<CosaFaccio />} />
          </Routes>
        </div>
        <BottomBar />
      </div>
    </HashRouter>
  )
}

export default App